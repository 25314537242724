.App-auth {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .card-auth{
    width: 400px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 40px;
    text-align: center;
  }
  
  .App-logo-auth {
    width: 150px;
    margin-bottom: 20px;
  }
  
  button {
    display: block;
    /* width: 100%; */
    padding: 15px;
    margin: 10px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .sign-in-button-auth {
    background-color: #204595;
    width: 100%;
    color: white;
  }
  
  /* .sign-up-button-auth {
    background-color: #007bff;
    width: 100%;
    color: white;
  } */
  
  .sign-up-button-auth {
    background-color: transparent; /* No fill color */
    border: 2px solid #204595; /* Border color matching the original background */
    color: #204595; /* Text color matching the original background */
    width: 100%;
    padding: 10px; /* Add padding for better appearance */
    border-radius: 8px; /* Rounded corners */
    cursor: pointer;
    font-size: 16px; /* Adjust font size if needed */
    text-align: center;
}
  .register-form-auth {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .form-input-auth {
    margin: 10px 0;
    padding: 15px;
    width: calc(100% - 30px);
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .register-button-auth {
    background-color: #204595;
    width: 100%;
    color: white;
  }
  
  /* .cancel-button-auth {
    background-color: #dc3545;
    color: white;
    width: 100%;
    margin-top: 10px;
  } */
  
  .cancel-button-auth {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none; /* or your desired background */
    border: none; /* or your desired border */
    color: #000; /* adjust color as needed */
    font-size: 16px; /* adjust size as needed */
    cursor: pointer;
}

.back-arrow {
  margin-right: 8px; /* space between arrow and text */
  font-size: 24px; /* adjust size as needed */
  line-height: 1; /* set line height to align with text */
  vertical-align: middle; /* center arrow vertically with text */
}
  .logout-button-auth {
    background-color: #204595;
    width: 100%;
    color: white;
  }

  .forgot-password-link {
    margin-top: auto; /* push the link to the bottom */
    text-align: center;
}
  