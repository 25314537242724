html {
  min-height: 100%;
  background-color: #fff !important;
}

@font-face {
  font-family: Segoe UI;
  src: url(./assets/fonts/Segoe\ UI\ 400.ttf);
  font-weight: 400;
}

@font-face {
  font-family: Segoe UI;
  src: url(./assets/fonts/Segoe\ UI\ Semibold\ 600.ttf);
  font-weight: 500;
}

@font-face {
  font-family: Segoe UI;
  src: url(./assets/fonts/Segoe\ UI\ Semibold\ 600.ttf);
  font-weight: 600;
}

@font-face {
  font-family: Segoe UI;
  src: url(./assets/fonts/Segoe\ UI\ Gras\ 700.ttf);
  font-weight: 700;
}

@font-face {
  font-family: Segoe UI;
  src: url(./assets/fonts/Segoe\ UI\ Black\ 900.ttf);
  font-weight: 900;
}

body {
  margin: 0;
  font-family: "Segoe UI" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600 !important;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  letter-spacing: normal !important;
  color: #000 !important;
}

/* Making cards selectable */
td {
  color: #000 !important;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
  /* background-color: #fff !important; */

  /* background-color: #f0f0f0 !important; */
}

.auth0-lock-center {
  background-color: #fff !important;
}


.auth0-lock-widget {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

}

@media (min-width: 566px) {
  ::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }

  /* background of the scrollbar except button or resizer */
  ::-webkit-scrollbar-track {
    background-color: #fff;
  }

  ::-webkit-scrollbar-track:hover {
    background-color: #f4f4f4;
  }

  /* scrollbar itself */
  ::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 5px solid #fff;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a5;
    border: 4px solid #f4f4f4;
  }

  /* set button(top and bottom of the scrollbar) */
  ::-webkit-scrollbar-button {
    display: none;
  }
}

.MuiOutlinedInput-multiline {
  padding-top: 10px !important;
}