@import url(https://fonts.googleapis.com/css2?family=Spartan:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Ubuntu:wght@400;500;700&display=swap);
html {
  min-height: 100%;
  background-color: #fff !important;
}

@font-face {
  font-family: Segoe UI;
  src: url("/static/media/Segoe UI 400.55d336ac.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Segoe UI;
  src: url("/static/media/Segoe UI Semibold 600.6e8b789f.ttf");
  font-weight: 500;
}

@font-face {
  font-family: Segoe UI;
  src: url("/static/media/Segoe UI Semibold 600.6e8b789f.ttf");
  font-weight: 600;
}

@font-face {
  font-family: Segoe UI;
  src: url("/static/media/Segoe UI Gras 700.bb81983d.ttf");
  font-weight: 700;
}

@font-face {
  font-family: Segoe UI;
  src: url("/static/media/Segoe UI Black 900.443f6e7a.ttf");
  font-weight: 900;
}

body {
  margin: 0;
  font-family: "Segoe UI" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600 !important;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  letter-spacing: normal !important;
  color: #000 !important;
}

/* Making cards selectable */
td {
  color: #000 !important;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
  /* background-color: #fff !important; */

  /* background-color: #f0f0f0 !important; */
}

.auth0-lock-center {
  background-color: #fff !important;
}


.auth0-lock-widget {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

}

@media (min-width: 566px) {
  ::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }

  /* background of the scrollbar except button or resizer */
  ::-webkit-scrollbar-track {
    background-color: #fff;
  }

  ::-webkit-scrollbar-track:hover {
    background-color: #f4f4f4;
  }

  /* scrollbar itself */
  ::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 5px solid #fff;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a5;
    border: 4px solid #f4f4f4;
  }

  /* set button(top and bottom of the scrollbar) */
  ::-webkit-scrollbar-button {
    display: none;
  }
}

.MuiOutlinedInput-multiline {
  padding-top: 10px !important;
}
*, *:before, *:after {
  box-sizing: border-box;
}

/* h1, h2, h3, h4, h5, h6 {
  font-family: "Segoe UI" !important;
  letter-spacing: -0.3px;
} */

/* th {
  font-family: "Segoe UI" !important;
}

td {
  font-family: "Segoe UI" !important;
}

legend {
  font-family: "Segoe UI" !important;
} */

.MuiToolbar-gutters {
  padding-right: 0px !important;
}

@media (max-width: 566px) {
  .MuiToolbar-gutters {
    padding-left: 5px !important;
  }
}

@media (min-width: 768px) {
  .border-md-x {
    border-left: 1px solid #e3e7eb !important;
    border-right: 1px solid #e3e7eb !important;
  }
}

.color-primary {
  color: #1f3a93;
}

.color-warning {
  color: #ffa412;
}

.color-success {
  color: #3aaa35;
}

.color-danger {
  color: #e3211f;
}

@media (min-width: 768px) {
  .htmlCssLegendsPadding {
    padding: 15px 60px !important;
  }
}

.speedIcon {
  margin-left: 0px;
}

@media (min-width: 768px) and (max-width: 768px) {
  .speedIcon {
    margin-left: 10px;
  }
}

.htmlCssLegendsPadding {
  padding: 0;
}

.containerCheckbox {
  display: block !important;
  position: relative !important;
  cursor: pointer !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  height: 40px !important;
  width: 40px !important;
}

/* Hide the browser's default radio button */

.containerCheckbox input {
  position: absolute !important;
  opacity: 0 !important;
  cursor: pointer !important;
}

.radio:focus+.containerCheckbox {
  border: 1px solid black !important;
}

/* Create a custom radio button */

.checkmark {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 25px !important;
  width: 25px !important;
  background-color: #eee !important;
  border-radius: 50% !important;
  transform: translateY(10px);
}

/* On mouse-over, add a grey background color */

.containerCheckbox:hover input~.checkmark {
  background-color: #ccc !important;
}

/* When the radio button is checked, add a blue background */

.containerCheckbox input:checked~.checkmark {
  background-color: rgb(31, 58, 147) !important;
}

/* Create the indicator (the dot/circle - hidden when not checked) */

.checkmark:after {
  content: "" !important;
  position: absolute !important;
  display: none !important;
}

/* Show the indicator (dot/circle) when checked */

.containerCheckbox input:checked~.checkmark:after {
  display: block !important;
}

/* Style the indicator (dot/circle) */

.containerCheckbox .checkmark:after {
  left: 9px !important;
  top: 5px !important;
  width: 7px !important;
  height: 12px !important;
  border: solid white !important;
  border-width: 0 2px 2px 0 !important;
  transform: rotate(45deg) !important;
}

.shadow-critical {
  box-shadow: 0px 3px 13px #e3211f !important;
}

.shadow-low {
  box-shadow: 0px 3px 13px gold !important;
}

.shadow-medium {
  box-shadow: 0px 3px 13px orange !important;
}

.shadow-safe {
  box-shadow: 0px 3px 13px #3aaa35 !important;
}

.numberCircle {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 8px;
  background: #fff;
  border: 1px solid #666;
  color: #666;
  text-align: center;
  font: 10px Arial, sans-serif;
}

.htmlCssCard {
  min-height: 420px !important;
}

@media (min-width: 566px) {
  .htmlCssCardTables {
    min-height: 200px;
    max-height: 200px;
    overflow-y: scroll;
  }
}

.htmlCssCardTables {
  min-height: auto;
  max-height: auto;
  overflow-y: scroll;
}

@media (min-width: 566px) {
  .cardsHeight {
    height: 100% !important;
  }
}

.cardsHeight {
  height: auto;
}

.mb-8 {
  margin-bottom: 8px;
}

.mr-8 {
  margin-right: 8px;
}

.mr-4 {
  margin-right: 4px;
}

.mr-15 {
  margin-right: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.listMain {
  padding-left: 0px;
}

.listMain li {
  margin: 10px 0px;
}

@media (min-width: 991px) {
  .listMain {
    padding-left: 10px;
  }
}

@media (max-width: 768px) {
  .heightResponsive {
    min-height: 250px !important;
  }
}

.heightResponsive {
  min-height: 100%;
}

.lotties {
  display: grid;
  grid-template-columns: auto auto;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .lotties {
    display: grid;
    grid-template-columns: auto;
  }
}

.auth0-lock-custom-icon {
  width: 14px;
  left: 12px !important;
}

.helpButton {
  color: #1f3a93;
  width: 100%;
  border: 1px solid #89c4f4;
  padding: 16px 22px;
  margin-bottom: 1rem;
  text-transform: none;
  text-transform: initial;
  background-color: white;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Segoe UI", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  cursor: pointer;
}

.activeHelpButton {
  background-color: #1f3a93;
  color: white;
  border: 1px solid #1f3a93;
  width: 100%;
  padding: 16px 22px;
  margin-bottom: 1rem;
  text-transform: none;
  text-transform: initial;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Segoe UI", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  cursor: pointer;
}

.MuiButton-label {
  width: auto !important;
}

.tooltiptext {
  width: 120px;
  min-width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  z-index: 999;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

/* .MuiTableCell-root:nth-child(1) {
vertical-align: top !important;
} */

/* 
.ReactVirtualized__Grid__innerScrollContainer{
overflow: auto !important;
} */

/* Hide scrollbar for Chrome, Safari and Opera */

.MuiPaper-root::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */

.MuiPaper-root {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.MuiListItemText-primary {
  font-size: 15px !important;
}

.MuiGridCustom-grid-md-2-5-3 {
  flex-grow: 0;
  max-width: 28.5714285714%;
  flex-basis: 28.5714285714%;
  padding: 16px;
}

.MuiGridCustom-grid-md-2-5 {
  flex-grow: 0;
  max-width: 14.2857142857%;
  flex-basis: 14.2857142857%;
  padding: 16px;
}

@media (max-width: 566px) {
  .MuiGridCustom-grid-md-2-5 {
    max-width: 100%;
    flex-basis: 100%;
  }
}

@media (min-width: 567px) and (max-width: 1920px) {
  .MuiGridCustom-grid-md-2-5 {
    flex-grow: 0;
    max-width: 33.3333%;
    flex-basis: 33.3333%;
    padding: 16px;
  }

  .MuiGridCustom-grid-md-2-5-3 {
    flex-grow: 0;
    max-width: 33.3333%;
    flex-basis: 33.3333%;
    padding: 16px;
  }
}

.activeButton {
  color: #343aa3;
  border-bottom: 1px solid #343aa3;
  padding: 0px 6px;
}

.notActiveButton {
  width: auto;
  padding: 0px 6px;
}

.notActiveButton:hover {
  cursor: pointer;
  border-bottom: 1px solid #343aa3;
}

.numberCircle {
  border-radius: 50%;
  width: 31px;
  height: 31px;
  padding: 3px 8px;
  background: #fff;
  border: 2px solid black;
  color: black;
  text-align: center;
  font: 20px Arial, sans-serif;
  font-weight: bold;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  width: auto;
  flex-wrap: wrap;
  padding: 0;
}

.pagination>li {
  margin: 5px 5px;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  padding: 0px !important;
  display: flex;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}

.pagination>li>a {
  text-decoration: none;
  padding: 4px 12px;
  display: block;
}

.pagination>li.active>a {
  color: white;
}

.pagination>li.active {
  background-color: #1f3a93;
}

.MuiGridCustom-grid-md-2-5-3 {
  flex-grow: 0;
  max-width: 28.5714285714%;
  flex-basis: 28.5714285714%;
  padding: 16px;
}

.MuiGridCustom-grid-md-2-5 {
  flex-grow: 0;
  max-width: 20%;
  flex-basis: 20%;
  padding: 16px;
}

@media (max-width: 566px) {
  .MuiGridCustom-grid-md-2-5 {
    max-width: 100%;
    flex-basis: 100%;
  }
}

@media (min-width: 567px) and (max-width: 1024px) {
  .MuiGridCustom-grid-md-2-5 {
    flex-grow: 0;
    max-width: 33.3333%;
    flex-basis: 33.3333%;
    padding: 16px;
  }

  .MuiGridCustom-grid-md-2-5-3 {
    flex-grow: 0;
    max-width: 33.3333%;
    flex-basis: 33.3333%;
    padding: 16px;
  }
}

.MuiGridCustom-grid2-md-2-5-3 {
  flex-grow: 0;
  max-width: 44%;
  flex-basis: 44%;
  padding: 5px;
}

.MuiGridCustom-grid2-md-2-5 {
  flex-grow: 0;
  max-width: 28%;
  flex-basis: 28%;
  padding: 5px;
}

@media (max-width: 566px) {
  .MuiGridCustom-grid2-md-2-5 {
    max-width: 100%;
    flex-basis: 100%;
  }

  .MuiGridCustom-grid2-md-2-5-3 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
  }
}

@media (min-width: 567px) and (max-width: 1024px) {
  .MuiGridCustom-grid2-md-2-5 {
    flex-grow: 0;
    max-width: 50%;
    flex-basis: 50%;
    padding: 5px;
  }

  .MuiGridCustom-grid2-md-2-5-3 {
    flex-grow: 0;
    max-width: 50%;
    flex-basis: 50%;
    padding: 5px;
  }
}

.color-critical {
  color: #e2211e !important;
}

.color-moderate {
  color: #ffd800 !important;
}

.color-moderate2 {
  color: #ffa412 !important;
}

.color-safe {
  color: #21ca5a !important;
}

.circle-critical {
  border: 2px solid #e2211e !important;
  color: #e2211e !important;
}

.circle-moderate {
  border: 2px solid #ffd800 !important;
  color: #ffd800 !important;
}

.circle-moderate2 {
  border: 2px solid #ffa412 !important;
  color: #ffa412 !important;
}

.circle-safe {
  border: 2px solid #21ca5a !important;
  color: #21ca5a !important;
}

.border-critical {
  border: 3px solid #e2211e !important;
}

.border-safe {
  border: 3px solid #21ca5a !important;
}

/* .textLeft {
  text-align: left !important;
} */

iframe {
  border: none;
  outline: none;
}

.csv-label {
  display: none !important;
}

.react-csv-input {
  margin-top: 0px !important;
  margin-right: 0px !important;
  display: none;
}

.csv-input {
  margin: 0px !important;
  padding: 0px !important;
  border: none !important;
}

.selectedLink {
  background-color: #ffa412 !important;
}

tr:focus {
  border: 2px dashed black;
}

.Toastify__toast--success {
  background-color: #218b34 !important;
}

.Toastify__toast--error {
  background-color: #e2211e !important;
}

.Toastify__toast--warning {
  background-color: #da9627 !important;
}

.Toastify__toast-body {
  font-size: 15px !important;
  font-weight: bold !important;
}

div[class^="PrivateHiddenCss-"] {
  height: 100% !important;
}

.MuiListItemText-primary {
  font-weight: 600 !important;
}

/* Date Range Picker Css Start */

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
  background: #1f3a93 !important;
  border: 1px double #1f3a93 !important;
  color: #fff !important;
}

.CalendarDay__selected_span {
  background: #7d95e4 !important;
  border: 1px double #7d95e4 !important;
  color: #fff !important;
}

.DateRangePickerInput__withBorder {
  border: 1px double #1f3a93 !important;
}

.DateInput {
  width: 105px !important;
}

.DateInput_input {
  font-size: 17px !important;
  padding: 5px 8px 3px !important;
}

.CalendarDay__hovered_span:hover, .CalendarDay__hovered_span {
  background: #7d95e4 !important;
  border: 1px double #7d95e4 !important;
  color: #fff !important;
}

/* Date Range Picker Css End */

/*  SECTIONS  */

.section {
  clear: both;
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-wrap: wrap;
}

/*  COLUMN SETUP  */

.col {
  display: block;
  padding: 1% 0 1% 0.5%;
}

.col:first-child {
  margin-left: 0;
}

/*  GROUPING  */

.group:before, .group:after {
  content: "";
  display: table;
}

.group:after {
  clear: both;
}

.group {
  zoom: 1;
  /* For IE 6/7 */
}

/*  GRID OF FIVE  */

.span_md_1_of_5 {
  width: 19.6%;
  /* min-height: 120px; */
}

.span_md_3_of_5 {
  width: 33%;
  /* min-height: 120px; */
}

.span_1_of_2 {
  width: 49.2%;
}

/*  GO FULL WIDTH BELOW 480 PIXELS */

@media only screen and (max-width: 786px) {
  .col {
    padding: 1% 0 1% 0.5%;
  }

  .span_md_1_of_5 {
    width: 32%;
  }

  .span_md_3_of_5 {
    width: 50%;
  }
}

@media only screen and (max-width: 540px) {
  .col {
    padding: 1% 0 1% 0.5%;
  }

  .span_md_1_of_5 {
    width: 50%;
  }

  .span_md_3_of_5 {
    width: 50%;
  }
}

@media only screen and (max-width: 480px) {
  .col {
    padding: 1% 0 1% 0.5%;
  }

  .span_md_1_of_5 {
    width: 100%;
  }

  .span_md_3_of_5 {
    width: 100%;
  }
}

/* Ribbon */

.ribbon {
  line-height: 1.7;
  font-size: 13px;
  text-transform: uppercase;
  text-align: center;
  display: block;
  width: 50px;
  height: 22px;
  right: -4px;
  top: 1px;
  color: white;
  position: absolute;
  border-bottom-left-radius: 10px;
  font-weight: 600;
  font-family: "Segoe UI";
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .ribbon {
    width: 42px;
  }
}

.ribbon-danger {
  background: #e2211e;
}

.ribbon-warning {
  background: #ffa412;
}

.ribbon-success {
  background: #21ca5a;
}

.ribbon-blue {
  background: #1f3a93;
}

/* card border */

.cardBorder-danger {
  border-bottom: 2px #e2211e solid !important;
}

.cardBorder-warning {
  border-bottom: 2px #ffa412 solid !important;
}

.cardBorder-success {
  border-bottom: 2px #21ca5a solid !important;
}

.recharts-wrapper {
  margin: auto !important;
}

.rotate-down {
  transform: rotate(90deg);
}

.rotate-up {
  transform: rotate(270deg);
}

.whiteBoxCardHeight {
  min-height: 140px;
}

@media only screen and (max-width: 1440px) and (min-width: 768px) {
  .whiteBoxCardHeight {
    min-height: 154px;
  }
}

.blueBoxCardHeight {
  min-height: 108px;
}

.MuiButtonBase-root {
  text-transform: capitalize;
}

/* .MuiOutlinedInput-input {
  padding: 12px !important;
} */

.MuiButtonBase-root:disabled {
  cursor: not-allowed !important;
  pointer-events: auto !important;
}

/* .archive-links-table
  > div
  > div
  > div
  > div
  > div
  > table
  > thead
  > tr:nth-child(1)
  .MuiSvgIcon-root {
  color: white !important;
} */

.ps-stic {
  position: -webkit-sticky;
  position: sticky;
  display: flow-root;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  margin-top: 85px;
}

.ps-stic2 {
  display: flow-root;
  text-align: center;
}

.homepage-text p {
  font-size: 35px;
  color: #203b94;
  font-family: "Segoe UI", sans-serif;
}

.homepage-text span {
  font-weight: 700;
}

.dialog-text-free-users p {
  margin: 0;
  font-size: 20px;
  color: #203b94;
  font-family: "Segoe UI", sans-serif;
}

.dialog-text-free-users span {
  font-weight: 700;
}

.img-fluid {
  height: auto;
  width: 100%;
}

.MuiDrawer-paper {
  position: fixed !important;
}

.buttonCheck {
  font-size: 20px !important;
  line-height: 1.4 !important;
  min-height: 44px !important;
  border-radius: 30px !important;
  max-width: 135px !important;
  width: 100% !important;
  padding: 12px 16px !important;
  font-weight: bold !important;
  background-color: #E59EFE !important;
  color: #951888 !important;
}

@media only screen and (max-width: 600px) {
  .homepage-text p {
    font-size: 25px;
  }

  .buttonCheck {
    width: 120px !important;
    font-size: 12px !important;
  }
}

.sidebarIcons {
  color: white;
}

.MuiAccordionSummary-expandIcon {
  margin-right: -8px !important;
}

.icon-priority {
  font-weight: 500;
}

.activity-logs-table thead tr {
  border: 2px solid #1f3a93 !important;
}

@media only screen and (min-width: 1367px) {
  .box-container {
    padding: 15px 50px !important;
  }
}

.MuiButton-label {
  font-family: 'Segoe UI', 'sans-serif' !important;
  text-transform: capitalize !important;
}

.MuiIconButton-label {
  font-family: 'Segoe UI', 'sans-serif' !important;
}

.MuiTab-wrapper {
  font-family: "Segoe UI" !important;
}

/* .MuiListItemText-primary {
  font-family: "Segoe UI" !important;
} */

.MuiFormControlLabel-label {
  font-family: "Segoe UI" !important;
}

.Toastify__toast-container {
  z-index: 99999999 !important;
}

/* 
.MuiTableCell-root.MuiTableCell-body {
  word-break: break-all
} */

.MuiInput-underline:before {
  border-bottom: 2px #1F3A93 solid !important;
}

#scans-table div>div>div {
  min-height: 32px !important;
}

#scans-table>div>div:nth-child(1)>div:nth-child(1) {
  padding-top: 15px !important;
}

@media (min-width: 600px) {
  #scans-table div>div>div {
    min-height: 32px !important;
  }
}

.package-sub-heading {
  margin: 0;
  font-weight: bold;
}

.package-sub-detail {
  margin: 0;
}

@keyframes fadeout {
  0% {
    opacity: 0;
    transform: scale(0.6);
  }

  50% {
    opacity: 0.5;
    transform: scale(0.8);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate {
  animation: 0.3s ease-out fadeout 1;
}

.check-list {
  margin: 0;
  padding-left: 1.2rem;
}

.check-list li {
  position: relative;
  list-style-type: none;
  padding-left: 2rem;
  margin-bottom: 0.5rem;
  font-size: 16px;
  font-family: 'Segoe UI';
  font-weight: 500;
}

.check-list li:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: -2px;
  width: 7px;
  height: 13px;
  border-width: 0 3px 3px 0;
  border-style: solid;
  border-color: #00a8a8;
  transform-origin: bottom left;
  transform: rotate(45deg);
}

.MuiDialog-paperWidthLg {
  max-width: 1100px !important
}

.emailVerifyBar {
  width: 100%;
  position: absolute;
  left: 250px;
  top: 60px;
}

@media (max-width: 600px) {
  .emailVerifyBar {
    left: 0px;
  }
}

.manage-resolutions {
  max-height: 230px;
  overflow-y: auto;
}

.manage-resolutions h3 {
  margin-top: 0;
  margin-bottom: 10px;
  max-height: 230px;
}

.resolutions-table .MuiFormControlLabel-root {
  font-size: 14px;
  font-weight: 500;
  margin: 0px;
}

.resolutions-table .MuiFormControlLabel-label {
  margin-left: 10px;
}

.resolutions-table .MuiIconButton-root {
  padding: 0px;
}

.resolutions-table {
  margin-bottom: 20px;
  border-collapse: collapse
}

.resolutions-table td:first-child {
  min-width: 250px
}

.resolutions-table td {
  padding: 10px 15px;
  border: 1px solid grey;
  min-width: 170px
}

.resolutions-table td {
  padding: 10px 15px;
  border: 1px solid grey;
}

.stat-card-dash .MuiAccordionSummary-expandIcon.Mui-expanded {
  transform: rotate(0deg) !important;
}

.progress-box {
  padding: 10px !important;
}

.progress-box .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  margin: 0px;
}

@media (max-width: 600px) {
  .responsive-header {
    width: 100%;
  }
}

.MuiGrid-grid-md-2-5 {
  flex-grow: 0;
  max-width: 20%;
  flex-basis: 20%;
}

.read-more-button {
  cursor: pointer;
  text-decoration: underline;
  font-size: 11px;
  margin-top: 5px;
}

/* .drawer-links .MuiListItemText-root {
  margin: 0px !important
} */

.drawer-links .MuiListItem-root {
  margin: 5px 0px !important;
}

.mr-2 {
  margin-right: 0.6rem !important;
}

.ml-2 {
  margin-left: 0.6rem !important;
}

#react-select-3-listbox {
  min-width: 400px !important;
}

.MuiGrid-spacing-xs-2>.MuiGrid-item {
  width: 100%;
}

.DayPicker_focusRegion {
  margin-top: 70px !important;
}


.title-issue:hover {
  cursor: pointer;
  color: #2457a3;
  text-decoration: underline;
}

.MuiOutlinedInput-input {
  padding: 10.5px 14px !important;
}
.App-auth {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .card-auth{
    width: 400px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 40px;
    text-align: center;
  }
  
  .App-logo-auth {
    width: 150px;
    margin-bottom: 20px;
  }
  
  button {
    display: block;
    /* width: 100%; */
    padding: 15px;
    margin: 10px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .sign-in-button-auth {
    background-color: #204595;
    width: 100%;
    color: white;
  }
  
  /* .sign-up-button-auth {
    background-color: #007bff;
    width: 100%;
    color: white;
  } */
  
  .sign-up-button-auth {
    background-color: transparent; /* No fill color */
    border: 2px solid #204595; /* Border color matching the original background */
    color: #204595; /* Text color matching the original background */
    width: 100%;
    padding: 10px; /* Add padding for better appearance */
    border-radius: 8px; /* Rounded corners */
    cursor: pointer;
    font-size: 16px; /* Adjust font size if needed */
    text-align: center;
}
  .register-form-auth {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .form-input-auth {
    margin: 10px 0;
    padding: 15px;
    width: calc(100% - 30px);
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .register-button-auth {
    background-color: #204595;
    width: 100%;
    color: white;
  }
  
  /* .cancel-button-auth {
    background-color: #dc3545;
    color: white;
    width: 100%;
    margin-top: 10px;
  } */
  
  .cancel-button-auth {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none; /* or your desired background */
    border: none; /* or your desired border */
    color: #000; /* adjust color as needed */
    font-size: 16px; /* adjust size as needed */
    cursor: pointer;
}

.back-arrow {
  margin-right: 8px; /* space between arrow and text */
  font-size: 24px; /* adjust size as needed */
  line-height: 1; /* set line height to align with text */
  vertical-align: middle; /* center arrow vertically with text */
}
  .logout-button-auth {
    background-color: #204595;
    width: 100%;
    color: white;
  }

  .forgot-password-link {
    margin-top: auto; /* push the link to the bottom */
    text-align: center;
}
  
.text-content .initial-static-text {
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.text-content {
  margin-top: 30px;
  margin-left: 40px;
}

.text-content h1 {
  padding: 0;
  margin: 0;

  color: #11193d;
  /* text-shadow: 0px 2px 0 rgba(0, 0, 0, 0.5); */
}

.text-content h1 span:before {
  content: "";
  color: black;
  background-color: yellow;
  animation: animate ease infinite 16s;
}

@keyframes animate {
  0% {
    content: "Cyber Security Vulnerabilities";
    opacity: 0;
  }

  3% {
    content: "Cyber Security Vulnerabilities";
    opacity: 1;
  }

  12% {
    content: "Cyber Security Vulnerabilities";
    opacity: 1;
  }

  15% {
    content: "Cyber Security Vulnerabilities";
    opacity: 0;
  }

  17% {
    content: "Speed and Performance Issues";
    opacity: 0;
  }

  20% {
    content: "Speed and Performance Issues";
    opacity: 1;
  }

  30% {
    content: "Speed and Performance Issues";
    opacity: 1;
  }

  35% {
    content: "Speed and Performance Issues";
    opacity: 0;
  }

  37% {
    content: "Web Accessibility Violations";
    opacity: 0;
  }

  40% {
    content: "Web Accessibility Violations";
    opacity: 1;
  }

  50% {
    content: "Web Accessibility Violations";
    opacity: 1;
  }

  55% {
    content: "Web Accessibility Violations";
    opacity: 0;
  }

  57% {
    content: "Spelling and Grammatical errors";
    opacity: 0;
  }

  60% {
    content: "Spelling and Grammatical errors";
    opacity: 1;
  }

  70% {
    content: "Spelling and Grammatical errors";
    opacity: 1;
  }

  75% {
    content: "Spelling and Grammatical errors";
    opacity: 0;
  }

  77% {
    content: "HTML, CSS and JavaScript errors";
    opacity: 0;
  }

  80% {
    content: "HTML, CSS and JavaScript errors";
    opacity: 1;
  }

  90% {
    content: "HTML, CSS and JavaScript errors";
    opacity: 1;
  }

  95% {
    content: "HTML, CSS and JavaScript errors";
    opacity: 0;
  }

  97% {
    content: "Web Accessibility Violations";
    opacity: 0;
  }

  100% {
    content: "Cyber Security Vulnerabilities";
    opacity: 0;
  }
}

.website-input {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 8px;
  border: 1px solid #2564c1;
  font-size: 17px;

  outline: none;
}

.em {
  margin-left: 20px;
}

.btn-main {
  margin-top: 14px;
  display: flex;
  flex-direction: row;
}

.free-btn {
  color: white;
  background-color: rgb(11, 54, 156);
  padding-top: 12px;
  padding-bottom: 11px;
  font-size: 15px;
  padding-left: 13px;
  cursor: pointer;
  font-family: "Spartan", sans-serif;
  border: 1px solid rgb(11, 54, 156);
  padding-right: 15px;
  display: flex;
  align-items: center;

}

.free-btn:hover {
  background-color: rgb(0, 35, 116);
}

.credit-card {
  display: inline;
  margin-left: 12px;
  margin-top: 7px;
  color: rgb(12, 159, 94);
  text-align: end;
}

:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #2564c1;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #2564c1;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
}

.audit-btn {
  margin-left: 5px;
  padding: 14px 25px;
  border-radius: 25px;
  background-color: #0a975f;
  color: white;
  cursor: pointer;
  border: none;
  font-size: 18px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.audit-btn:active {
  background-color: #077549;
}

.main-input {
  display: flex;
  flex-wrap: wrap;
  color: #11193d;
  margin-top: 10px;
  margin-left: 40px;
  flex-direction: column;
}

@media (max-width: 1075px) {
  .text-content {
    margin-top: 30px;
    margin-left: 40px;
    width: 80%;
  }

  .main-input {
    color: #11193d;
    margin-top: 40px;
    margin-left: 40px;
    width: 80%;
    font-size: 15px;
  }
}

@media (max-width: 630px) {
  .em {
    margin-left: 0px;
  }

  .website-input {
    padding: 8px 8px;
    margin-top: 10px;
    font-size: 15px;
    outline: none;
  }

  .free-btn {
    color: white;

    background-color: rgb(11, 54, 156);
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 15px;
    padding-left: 8px;

    font-family: "Spartan", sans-serif;
    border: 1px solid rgb(11, 54, 156);
    padding-right: 9px;
  }
}

@media (max-width: 400px) {
  .credit-card {
    display: block;
    margin-left: 0px;
    margin-top: 20px !important;
  }

  .website-input {
    padding: 8px 8px;
    margin-top: 10px;
    font-size: 15px;
    outline: none;
  }

  .main-input {
    color: #11193d;
    margin-top: 40px;
    margin-left: 40px;
    width: 45%;
    font-size: 10px;
  }

  .em {
    margin-left: 0px;
  }

  .audit-btn {
    display: flex;
    margin-top: 3px;
    padding: 9px 20px;
    border-radius: 25px;
    background-color: #0a975f;
    color: white;
    cursor: pointer;
    border: none;
    font-size: 13px;
    align-items: center;
    align-content: center;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  }

  .main-btn {
    display: flex;
    align-items: center;
    align-content: center;
    text-align: center;
  }

  :-ms-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #2564c1;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #2564c1;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
  }
}

@media (max-width: 400px) {
  h1 {
    font-size: 18px;
  }
}
.report-perf-boxes {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr;
  grid-gap: 25px;
}

.report-performance h3 {
  color: #3c9adc;
  font-weight: 500;
  font-size: 28px;
  margin-bottom: 4px;
}

.report-perf-box {
  display: flex;
  flex: 1 1 50%;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
}

.report-perf-box-text {
  padding: 18px 5px 18px 25px;
  flex: 1 1;
  position: relative;
  display: flex;
  align-items: center;
}

.report-perf-box-text h4 {
  font: 22px "Segoe UI",  sans-serif;
  letter-spacing: -0.0625em;
}

.report-perf-box-text i {
  margin-bottom: -2px;
}

.report-perf-box-text p {
  font-size: 15px;
  display: none;
}

.report-perf-box-text:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
}

.report-perf-box-performance h4 {
  margin: 12px;
}

.report-perf-box-performance .report-perf-box-result-numeric {
  padding-bottom: 5px;
}

.report-perf-box-expand {
  display: block;
}

.report-perf-box-expand h4 {
  margin: 0 0 16px;
}

.report-perf-box-expand h4 i {
  display: none;
}

.report-perf-box-expand p {
  margin: 0;
  display: inline;
}

.report-perf-box-result {
  width: 215px;
  display: flex;
  flex-direction: column;
}

.report-perf-box-result-message {
  font: 14px "Segoe UI",  sans-serif;
  letter-spacing: -0.0225em;
  width: 100%;
  text-align: center;
  padding: 7px 0;
}

.report-perf-box-result-numeric {
  font: 40px "Segoe UI",  sans-serif;
  letter-spacing: -0.0625em;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.report-perf-boxes-smaller {
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr 1fr;
}

.report-perf-boxes-smaller h4 {
  font-size: 18px;
  margin: 0;
}

.report-perf-boxes-smaller .report-perf-box-result-numeric {
  font-size: 22px;
}

.report-perf-boxes-smaller .report-perf-box-result {
  width: 90px;
}

.report-perf-box-rating-low .report-perf-box-result-numeric {
  background: #f5fcf4;
  color: #36a927;
}

.report-perf-box-rating-low .report-perf-box-result-message {
  background: #36a927;
  color: #fff;
}

.report-perf-box-rating-medium-low .report-perf-box-result-numeric {
  background: #f0f6e3;
  color: #99c144;
}

.report-perf-box-rating-medium-low .report-perf-box-result-message {
  background: #99c144;
  color: #fff;
}

.report-perf-box-rating-medium .report-perf-box-result-numeric {
  background: #fff2dd;
  color: #f6ab34;
}

.report-perf-box-rating-medium .report-perf-box-result-message {
  background: #f6ab34;
  color: #fff;
}

.report-perf-box-rating-medium-high .report-perf-box-result-numeric {
  background: #fff6e9;
  color: #c48a8a;
}

.report-perf-box-rating-medium-high .report-perf-box-result-message {
  background: #c48a8a;
  color: #fff;
}

.report-perf-box-rating-high .report-perf-box-result-numeric {
  background: #ffebe9;
  color: #ec685d;
}

.report-perf-box-rating-high .report-perf-box-result-message {
  background: #ec685d;
  color: #fff;
}

.report-perf-box-first-byte:before {
  background: #8a98c1;
}

.report-perf-box-first-contentful-paint:before {
  background: #2ebbf6;
}

.report-perf-box-largest-contentful-paint:before {
  background: #417be7;
}

.report-perf-box-interactive:before {
  background: #b06dc9;
}

.report-perf-box-speed-index:before {
  background: #f466a6;
}

.report-perf-box-onload:before {
  background: #a85c84;
}

.report-perf-box-fully-loaded:before {
  background: #b22f69;
}

.report-perf-box-cumulative-layout-shift:before {
  background: #4cc29d;
}

.report-perf-box-total-blocking-time:before {
  background: #6c467a;
}

.report-perf-box-redirect:before {
  background: #f4f4f4;
}

.report-perf-box-connect:before {
  background: #d9d9d9;
}

.report-perf-box-backend:before {
  background: #bebab9;
}

.report-perf-box-first-byte:before {
  background: #8a98c1;
}

.report-perf-box-first-paint:before {
  background: #506791;
}

.report-perf-box-dom-interactive:before {
  background: #527070;
}

.report-perf-box-dom-content-loaded:before {
  background: #408483;
}

@media not print {
  @media(max-width: 950px) {
    .report-perf-boxes {
      grid-template-columns: 1fr;
      grid-auto-rows: auto;
      grid-gap: 10px;
    }
    .report-perf-boxes-smaller {
      grid-template-columns: 1fr 1fr;
    }
    .report-perf-box-result-numeric {
      font: 30px "Segoe UI",  sans-serif;
      letter-spacing: -0.0625em;
    }
  }
  @media(max-width:800px) {
    .report-perf-boxes-smaller {
      grid-template-columns: 1fr;
    }
  }
}

@media(max-width:550px) {
  .report-perf-intro {
    flex-direction: column;
    align-items: center;
  }
  .report-perf-expand-wrapper {
    align-self: auto;
    margin-top: 15px;
  }
  .report-perf-box {
    flex-direction: column;
    margin: 30px 18px;
  }
  .report-perf-box .report-perf-box-result {
    width: 100%
  }
  .report-perf-box-text {
    padding: 15px;
    text-align: center;
    order: 2;
    justify-content: center;
  }
  .report-perf-box-text:before {
    right: 0;
    height: 4px;
    width: auto;
    width: initial;
  }
  .report-perf-box-result-numeric {
    padding: 10px 10px 18px;
  }
}

.recharts-tooltip-wrapper {
  z-index: 10000 !important;
}
.issue-tracker-table {
  margin: 2rem 0rem;
  overflow: auto;
  border-spacing: 0;
  border-collapse: collapse;
  box-shadow: 0px 3px 25px rgba(0 0 0 / 15%);
  overflow: hidden;
  border-radius: 10px
}

.cm-s-solarized-light {
  background-color: #f8f5ec;
  color: #637c84;
}

.cm-s-solarized-light .emphasis {
  font-weight: bold;
}

.cm-s-solarized-light .dotted {
  border-bottom: 1px dotted #cb4b16;
}

.cm-s-solarized-light .CodeMirror-gutter {
  background-color: #eee8d5;
  border-right: 3px solid #eee8d5;
}

.cm-s-solarized-light .CodeMirror-gutter .CodeMirror-gutter-text {
  color: #93a1a1;
}

.cm-s-solarized-light .CodeMirror-cursor {
  border-left-color: #002b36 !important;
}

.cm-s-solarized-light .CodeMirror-matchingbracket {
  color: #002b36;
  background-color: #eee8d5;
  box-shadow: 0 0 10px #eee8d5;
  font-weight: bold;
}

.cm-s-solarized-light .CodeMirror-nonmatchingbracket {
  color: #002b36;
  background-color: #eee8d5;
  box-shadow: 0 0 10px #eee8d5;
  font-weight: bold;
  color: #dc322f;
  border-bottom: 1px dotted #cb4b16;
}

.cm-s-solarized-light span.cm-keyword {
  color: #268bd2;
}

.cm-s-solarized-light span.cm-atom {
  color: #2aa198;
}

.cm-s-solarized-light span.cm-number {
  color: #586e75;
}

.cm-s-solarized-light span.cm-def {
  color: #637c84;
}

.cm-s-solarized-light span.cm-variable {
  color: #637c84;
}

.cm-s-solarized-light span.cm-variable-2 {
  color: #b58900;
}

.cm-s-solarized-light span.cm-variable-3 {
  color: #cb4b16;
}

.cm-s-solarized-light span.cm-comment {
  color: #93a1a1;
}

.cm-s-solarized-light span.cm-property {
  color: #637c84;
}

.cm-s-solarized-light span.cm-operator {
  color: #657b83;
}

.cm-s-solarized-light span.cm-string {
  color: #36958e;
}

.cm-s-solarized-light span.cm-error {
  font-weight: bold;
  border-bottom: 1px dotted #cb4b16;
}

.cm-s-solarized-light span.cm-bracket {
  color: #cb4b16;
}

.cm-s-solarized-light span.cm-tag {
  color: #657b83;
}

.cm-s-solarized-light span.cm-attribute {
  color: #586e75;
  font-weight: bold;
}

.cm-s-solarized-light span.cm-meta {
  color: #268bd2;
}

.cm-s-solarized-dark {
  background-color: #002b36;
  color: #839496;
}

.cm-s-solarized-dark .emphasis {
  font-weight: bold;
}

.cm-s-solarized-dark .dotted {
  border-bottom: 1px dotted #cb4b16;
}

.cm-s-solarized-dark .CodeMirror-gutter {
  background-color: #073642;
  border-right: 3px solid #073642;
}

.cm-s-solarized-dark .CodeMirror-gutter .CodeMirror-gutter-text {
  color: #586e75;
}

.cm-s-solarized-dark .CodeMirror-cursor {
  border-left-color: #fdf6e3 !important;
}

.cm-s-solarized-dark .CodeMirror-matchingbracket {
  color: #fdf6e3;
  background-color: #073642;
  box-shadow: 0 0 10px #073642;
  font-weight: bold;
}

.cm-s-solarized-dark .CodeMirror-nonmatchingbracket {
  color: #fdf6e3;
  background-color: #073642;
  box-shadow: 0 0 10px #073642;
  font-weight: bold;
  color: #dc322f;
  border-bottom: 1px dotted #cb4b16;
}

.cm-s-solarized-dark span.cm-keyword {
  color: #839496;
  font-weight: bold;
}

.cm-s-solarized-dark span.cm-atom {
  color: #2aa198;
}

.cm-s-solarized-dark span.cm-number {
  color: #93a1a1;
}

.cm-s-solarized-dark span.cm-def {
  color: #268bd2;
}

.cm-s-solarized-dark span.cm-variable {
  color: #cb4b16;
}

.cm-s-solarized-dark span.cm-variable-2 {
  color: #cb4b16;
}

.cm-s-solarized-dark span.cm-variable-3 {
  color: #cb4b16;
}

.cm-s-solarized-dark span.cm-comment {
  color: #586e75;
}

.cm-s-solarized-dark span.cm-property {
  color: #b58900;
}

.cm-s-solarized-dark span.cm-operator {
  color: #839496;
}

.cm-s-solarized-dark span.cm-string {
  color: #6c71c4;
}

.cm-s-solarized-dark span.cm-error {
  font-weight: bold;
  border-bottom: 1px dotted #cb4b16;
}

.cm-s-solarized-dark span.cm-bracket {
  color: #cb4b16;
}

.cm-s-solarized-dark span.cm-tag {
  color: #839496;
}

.cm-s-solarized-dark span.cm-attribute {
  color: #93a1a1;
  font-weight: bold;
}

.cm-s-solarized-dark span.cm-meta {
  color: #268bd2;
}

* {
  box-sizing: border-box;
  /* border: none;
  margin: 0;
  padding: 0; */
}

.rdg-selected {
  border-style: dotted;
}

/* .react-grid-Cell {
  cursor: url("https://img.icons8.com/fluent-systems-filled/18/000000/edit.png"), default !important;
} */

/* div.CodeMirror pre, div.CodeMirror-linenumber, code {
  font-family: 'source-code-pro', Menlo, Consolas, 'Courier New', monospace;
  font-size: 13px;
  line-height: 1.5;
} */

div.CodeMirror-linenumber {
  text-align: right;
}

.CodeMirror, div.CodeMirror-gutters, div.highlight {
  border: none;
}

.CodeMirror-readonly div.CodeMirror-cursor {
  visibility: hidden;
}

/* small code, li code, p code {
  color: #555;
  background-color: rgba(0, 0, 0, 0.04);
  padding: 1px 3px;
} */

.cm-s-default span.cm-string-2 {
  color: inherit;
}

.playground:after {
  content: "";
  display: table;
  clear: both;
}

.react-grid-HeaderCell{
font: 13px 'Segoe UI' 600;
text-transform: capitalize;
}

.playground-tab {
  border-bottom: none !important;
  border-radius: 3px 3px 0 0;
  padding: 6px 8px;
  font-size: 12px;
  font-weight: bold;
  color: #c2c0bc;
  background-color: #f1ede4;
  display: inline-block;
  cursor: pointer;
}

.playgroundCode, .playground-tab, .playgroundPreview {
  border: 1px solid rgba(16, 16, 16, 0.1);
}

.playground-tab-active {
  color: #222;
}

.playgroundCode {
  border-radius: 0 3px 3px 3px;
  float: left;
  overflow: hidden;
  width: 100%;
}

.playgroundPreview {
  background-color: white;
  border-radius: 3px;
  float: left;
  padding: 15px 20px;
  width: 100%;
}

.playgroundError {
  color: #c5695c;
  font-size: 15px;
}

.MarkdownEditor textarea {
  width: 100%;
  height: 100px;
}

.MarkdownEditor .content {
  white-space: pre-wrap;
}

.hll {
  background-color: #f7ebc6;
  border-left: 5px solid #f7d87c;
  display: block;
  margin-left: -14px;
  margin-right: -14px;
  padding-left: 9px;
}

.highlight .javascript .err {
  background-color: transparent;
  color: inherit;
}

.highlight {
  position: relative;
  margin-bottom: 14px;
  padding: 30px 14px 14px;
  border: none;
  border-radius: 0;
  overflow: auto;
}

.highlight pre {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  background-color: transparent;
  border: 0;
}

.highlight pre code {
  display: block;
  background: none;
  padding: 0;
}

.highlight pre .lineno {
  display: inline-block;
  width: 22px;
  padding-right: 5px;
  margin-right: 10px;
  color: #bebec5;
  text-align: right;
}

.highlight:after {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: bold;
  color: #c2c0bc;
  background-color: #f1ede4;
  content: "Code";
}

.downloadCenter {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 25px;
}

.downloadSection:hover {
  text-decoration: none !important;
}

@media screen and (max-width: 960px) {
  .nav-main {
    position: static;
  }
  .container {
    padding-top: 0;
  }
}

.post {
  margin-bottom: 30px;
}

.pagination {
  margin-bottom: 30px;
  width: 100%;
  overflow: hidden;
}

.pagination .next {
  float: right;
}

div[data-twttr-id] iframe {
  margin: 10px auto !important;
  width: 100% !important;
}

.three-column:after {
  content: "";
  display: table;
  clear: both;
}

.three-column>ul {
  float: left;
  margin-left: 30px;
  width: 190px;
}

.three-column>ul:first-child {
  margin-left: 20px;
}

.documentationContent .subHeader {
  font-size: 24px;
}

.documentationContent h2 {
  margin-top: 30px;
}

.documentationContent blockquote {
  padding: 15px 30px 15px 15px;
  margin: 20px 0;
  background-color: rgba(204, 122, 111, 0.1);
  border-left: 5px solid rgba(191, 87, 73, 0.2);
}

.documentationContent blockquote h4 {
  margin-top: 0;
}

.documentationContent blockquote p {
  margin-bottom: 0;
}

.documentationContent blockquote p:first-child {
  font-weight: bold;
  font-size: 17.5px;
  line-height: 20px;
  margin-top: 0;
  text-rendering: optimizelegibility;
}

.docs-prevnext {
  padding-top: 40px;
  padding-bottom: 40px;
}

.jsxCompiler {
  margin: 0 auto;
  padding-top: 20px;
  width: 1220px;
}

.jsxCompiler label.compiler-option {
  display: block;
  margin-top: 5px;
}

.jsxCompiler #jsxCompiler {
  margin-top: 20px;
}

.jsxCompiler .playgroundPreview {
  padding: 0;
  width: 600px;
}

.jsxCompiler .playgroundPreview pre {
  font-family: 'source-code-pro', Menlo, Consolas, 'Courier New', monospace;
  font-size: 13px;
  line-height: 1.5;
}

.jsxCompiler .playgroundError {
  padding: 15px 20px;
}

.pagination ul {
  display: inline-block;
  padding: 15px;
}

.pagination li {
  display: inline-block;
  padding: 15px;
  border: 1px solid gray;
}

.pagination .break a {
  cursor: pointer;
  padding: 15px;
}

.csv-label {
  font-weight: bold;
}

/* .csv-input {
  padding: 10px;
  display: block;
  margin: 15px auto;
  border: 1px solid #ccc;
  border-radius: 5px;
} */

.react-csv-input {
  margin-top: 20px;
  display: block;
}

.react-input {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
  cursor: pointer !important;
  filter: alpha(opacity=0);
  opacity: 0 !important;
}

.form-control {
  height: 30px !important;
  padding: 5px 5px !important;
}

.sr-only {
  border: 1px solid black !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.sr-only-focusable:focus, .sr-only-focusable:active {
  clip: auto !important;
  -webkit-clip-path: none !important;
  clip-path: none !important;
  height: auto !important;
  margin: auto !important;
  overflow: visible !important;
  width: auto !important;
  white-space: normal !important;
}

.outer-csv-div {
  border: 3px dashed #a0a0a08c !important;
  border-radius: 6px !important;
  padding: 50px 90px !important;
  text-align: center !important;
  cursor: pointer !important;
}

.outer-csv-div:focus-within {
  border: 3px solid #000 !important;
}

@media (max-width: 600px) {
  .outer-csv-div {
    padding: 40px 75px !important;
  }
}
/* @import '../node_modules/@syncfusion/ej2-base/styles/material.css'; */
/* @import "../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-grids/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-react-spreadsheet/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-react-dropdowns/styles/material.css"; */
.thankyou_main {
	padding: 0 20px;
	min-width: 300px;
	font-family: 'Akkurat-Regular', sans-serif;
	background-color: #fffffe;
	color: #1a1a1a;
	text-align: center;
	word-wrap: break-word;
	-webkit-font-smoothing: antialiased;
  height: 100vh;
}


.site-header {
	margin: 0 auto;
	padding: 80px 0 0;
	max-width: 820px;
}
.site-header__title {
	margin: 0;
	font-family: Montserrat, sans-serif;
	font-size: 2.5rem;
	font-weight: 700;
	line-height: 1.1;
	text-transform: uppercase;
	-webkit-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;
}


.main-content {
	margin: 0 auto;
	max-width: 820px;
}
.main-content__checkmark {
	font-size: 4.0625rem;
	line-height: 1;
	color: #24b663;
}
.main-content__body {
	margin: 20px 0 0;
	font-size: 1rem;
	line-height: 1.4;
}


.site-footer {
	margin: 0 auto;
	padding: 80px 0 25px;
	padding: 0;
	max-width: 820px;
}
.site-footer__fineprint {
	font-size: 0.9375rem;
	line-height: 1.3;
	font-weight: 300;
	font-family: Arial, sans-serif;
}



@media only screen and (min-width: 40em) {
	.site-header {
		padding-top: 150px;
	}
	.site-header__title {
		font-size: 6.25rem;
	}
	.main-content__checkmark {
		font-size: 9.75rem;
	}
	.main-content__body {
		font-size: 1.25rem;
	}
	.site-footer {
		padding: 145px 0 25px;
	}
	.site-footer__fineprint {
		font-size: 1.125rem;
		font-family: Arial, sans-serif;
	}
}
