@import url("https://fonts.googleapis.com/css2?family=Spartan:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Ubuntu:wght@400;500;700&display=swap");

.text-content .initial-static-text {
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.text-content {
  margin-top: 30px;
  margin-left: 40px;
}

.text-content h1 {
  padding: 0;
  margin: 0;

  color: #11193d;
  /* text-shadow: 0px 2px 0 rgba(0, 0, 0, 0.5); */
}

.text-content h1 span:before {
  content: "";
  color: black;
  background-color: yellow;
  animation: animate ease infinite 16s;
}

@keyframes animate {
  0% {
    content: "Cyber Security Vulnerabilities";
    opacity: 0;
  }

  3% {
    content: "Cyber Security Vulnerabilities";
    opacity: 1;
  }

  12% {
    content: "Cyber Security Vulnerabilities";
    opacity: 1;
  }

  15% {
    content: "Cyber Security Vulnerabilities";
    opacity: 0;
  }

  17% {
    content: "Speed and Performance Issues";
    opacity: 0;
  }

  20% {
    content: "Speed and Performance Issues";
    opacity: 1;
  }

  30% {
    content: "Speed and Performance Issues";
    opacity: 1;
  }

  35% {
    content: "Speed and Performance Issues";
    opacity: 0;
  }

  37% {
    content: "Web Accessibility Violations";
    opacity: 0;
  }

  40% {
    content: "Web Accessibility Violations";
    opacity: 1;
  }

  50% {
    content: "Web Accessibility Violations";
    opacity: 1;
  }

  55% {
    content: "Web Accessibility Violations";
    opacity: 0;
  }

  57% {
    content: "Spelling and Grammatical errors";
    opacity: 0;
  }

  60% {
    content: "Spelling and Grammatical errors";
    opacity: 1;
  }

  70% {
    content: "Spelling and Grammatical errors";
    opacity: 1;
  }

  75% {
    content: "Spelling and Grammatical errors";
    opacity: 0;
  }

  77% {
    content: "HTML, CSS and JavaScript errors";
    opacity: 0;
  }

  80% {
    content: "HTML, CSS and JavaScript errors";
    opacity: 1;
  }

  90% {
    content: "HTML, CSS and JavaScript errors";
    opacity: 1;
  }

  95% {
    content: "HTML, CSS and JavaScript errors";
    opacity: 0;
  }

  97% {
    content: "Web Accessibility Violations";
    opacity: 0;
  }

  100% {
    content: "Cyber Security Vulnerabilities";
    opacity: 0;
  }
}

.website-input {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 8px;
  border: 1px solid #2564c1;
  font-size: 17px;

  outline: none;
}

.em {
  margin-left: 20px;
}

.btn-main {
  margin-top: 14px;
  display: flex;
  flex-direction: row;
}

.free-btn {
  color: white;
  background-color: rgb(11, 54, 156);
  padding-top: 12px;
  padding-bottom: 11px;
  font-size: 15px;
  padding-left: 13px;
  cursor: pointer;
  font-family: "Spartan", sans-serif;
  border: 1px solid rgb(11, 54, 156);
  padding-right: 15px;
  display: flex;
  align-items: center;

}

.free-btn:hover {
  background-color: rgb(0, 35, 116);
}

.credit-card {
  display: inline;
  margin-left: 12px;
  margin-top: 7px;
  color: rgb(12, 159, 94);
  text-align: end;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #2564c1;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
}

.audit-btn {
  margin-left: 5px;
  padding: 14px 25px;
  border-radius: 25px;
  background-color: #0a975f;
  color: white;
  cursor: pointer;
  border: none;
  font-size: 18px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.audit-btn:active {
  background-color: #077549;
}

.main-input {
  display: flex;
  flex-wrap: wrap;
  color: #11193d;
  margin-top: 10px;
  margin-left: 40px;
  flex-direction: column;
}

@media (max-width: 1075px) {
  .text-content {
    margin-top: 30px;
    margin-left: 40px;
    width: 80%;
  }

  .main-input {
    color: #11193d;
    margin-top: 40px;
    margin-left: 40px;
    width: 80%;
    font-size: 15px;
  }
}

@media (max-width: 630px) {
  .em {
    margin-left: 0px;
  }

  .website-input {
    padding: 8px 8px;
    margin-top: 10px;
    font-size: 15px;
    outline: none;
  }

  .free-btn {
    color: white;

    background-color: rgb(11, 54, 156);
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 15px;
    padding-left: 8px;

    font-family: "Spartan", sans-serif;
    border: 1px solid rgb(11, 54, 156);
    padding-right: 9px;
  }
}

@media (max-width: 400px) {
  .credit-card {
    display: block;
    margin-left: 0px;
    margin-top: 20px !important;
  }

  .website-input {
    padding: 8px 8px;
    margin-top: 10px;
    font-size: 15px;
    outline: none;
  }

  .main-input {
    color: #11193d;
    margin-top: 40px;
    margin-left: 40px;
    width: 45%;
    font-size: 10px;
  }

  .em {
    margin-left: 0px;
  }

  .audit-btn {
    display: flex;
    margin-top: 3px;
    padding: 9px 20px;
    border-radius: 25px;
    background-color: #0a975f;
    color: white;
    cursor: pointer;
    border: none;
    font-size: 13px;
    align-items: center;
    align-content: center;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  }

  .main-btn {
    display: flex;
    align-items: center;
    align-content: center;
    text-align: center;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #2564c1;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
  }
}

@media (max-width: 400px) {
  h1 {
    font-size: 18px;
  }
}