*, *:before, *:after {
  box-sizing: border-box;
}

/* h1, h2, h3, h4, h5, h6 {
  font-family: "Segoe UI" !important;
  letter-spacing: -0.3px;
} */

/* th {
  font-family: "Segoe UI" !important;
}

td {
  font-family: "Segoe UI" !important;
}

legend {
  font-family: "Segoe UI" !important;
} */

.MuiToolbar-gutters {
  padding-right: 0px !important;
}

@media (max-width: 566px) {
  .MuiToolbar-gutters {
    padding-left: 5px !important;
  }
}

@media (min-width: 768px) {
  .border-md-x {
    border-left: 1px solid #e3e7eb !important;
    border-right: 1px solid #e3e7eb !important;
  }
}

.color-primary {
  color: #1f3a93;
}

.color-warning {
  color: #ffa412;
}

.color-success {
  color: #3aaa35;
}

.color-danger {
  color: #e3211f;
}

@media (min-width: 768px) {
  .htmlCssLegendsPadding {
    padding: 15px 60px !important;
  }
}

.speedIcon {
  margin-left: 0px;
}

@media (min-width: 768px) and (max-width: 768px) {
  .speedIcon {
    margin-left: 10px;
  }
}

.htmlCssLegendsPadding {
  padding: 0;
}

.containerCheckbox {
  display: block !important;
  position: relative !important;
  cursor: pointer !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  height: 40px !important;
  width: 40px !important;
}

/* Hide the browser's default radio button */

.containerCheckbox input {
  position: absolute !important;
  opacity: 0 !important;
  cursor: pointer !important;
}

.radio:focus+.containerCheckbox {
  border: 1px solid black !important;
}

/* Create a custom radio button */

.checkmark {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 25px !important;
  width: 25px !important;
  background-color: #eee !important;
  border-radius: 50% !important;
  transform: translateY(10px);
}

/* On mouse-over, add a grey background color */

.containerCheckbox:hover input~.checkmark {
  background-color: #ccc !important;
}

/* When the radio button is checked, add a blue background */

.containerCheckbox input:checked~.checkmark {
  background-color: rgb(31, 58, 147) !important;
}

/* Create the indicator (the dot/circle - hidden when not checked) */

.checkmark:after {
  content: "" !important;
  position: absolute !important;
  display: none !important;
}

/* Show the indicator (dot/circle) when checked */

.containerCheckbox input:checked~.checkmark:after {
  display: block !important;
}

/* Style the indicator (dot/circle) */

.containerCheckbox .checkmark:after {
  left: 9px !important;
  top: 5px !important;
  width: 7px !important;
  height: 12px !important;
  border: solid white !important;
  border-width: 0 2px 2px 0 !important;
  transform: rotate(45deg) !important;
}

.shadow-critical {
  box-shadow: 0px 3px 13px #e3211f !important;
}

.shadow-low {
  box-shadow: 0px 3px 13px gold !important;
}

.shadow-medium {
  box-shadow: 0px 3px 13px orange !important;
}

.shadow-safe {
  box-shadow: 0px 3px 13px #3aaa35 !important;
}

.numberCircle {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 8px;
  background: #fff;
  border: 1px solid #666;
  color: #666;
  text-align: center;
  font: 10px Arial, sans-serif;
}

.htmlCssCard {
  min-height: 420px !important;
}

@media (min-width: 566px) {
  .htmlCssCardTables {
    min-height: 200px;
    max-height: 200px;
    overflow-y: scroll;
  }
}

.htmlCssCardTables {
  min-height: auto;
  max-height: auto;
  overflow-y: scroll;
}

@media (min-width: 566px) {
  .cardsHeight {
    height: 100% !important;
  }
}

.cardsHeight {
  height: auto;
}

.mb-8 {
  margin-bottom: 8px;
}

.mr-8 {
  margin-right: 8px;
}

.mr-4 {
  margin-right: 4px;
}

.mr-15 {
  margin-right: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.listMain {
  padding-left: 0px;
}

.listMain li {
  margin: 10px 0px;
}

@media (min-width: 991px) {
  .listMain {
    padding-left: 10px;
  }
}

@media (max-width: 768px) {
  .heightResponsive {
    min-height: 250px !important;
  }
}

.heightResponsive {
  min-height: 100%;
}

.lotties {
  display: grid;
  grid-template-columns: auto auto;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .lotties {
    display: grid;
    grid-template-columns: auto;
  }
}

.auth0-lock-custom-icon {
  width: 14px;
  left: 12px !important;
}

.helpButton {
  color: #1f3a93;
  width: 100%;
  border: 1px solid #89c4f4;
  padding: 16px 22px;
  margin-bottom: 1rem;
  text-transform: initial;
  background-color: white;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Segoe UI", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  cursor: pointer;
}

.activeHelpButton {
  background-color: #1f3a93;
  color: white;
  border: 1px solid #1f3a93;
  width: 100%;
  padding: 16px 22px;
  margin-bottom: 1rem;
  text-transform: initial;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Segoe UI", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  cursor: pointer;
}

.MuiButton-label {
  width: auto !important;
}

.tooltiptext {
  width: 120px;
  min-width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  z-index: 999;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

/* .MuiTableCell-root:nth-child(1) {
vertical-align: top !important;
} */

/* 
.ReactVirtualized__Grid__innerScrollContainer{
overflow: auto !important;
} */

/* Hide scrollbar for Chrome, Safari and Opera */

.MuiPaper-root::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */

.MuiPaper-root {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.MuiListItemText-primary {
  font-size: 15px !important;
}

.MuiGridCustom-grid-md-2-5-3 {
  flex-grow: 0;
  max-width: 28.5714285714%;
  flex-basis: 28.5714285714%;
  padding: 16px;
}

.MuiGridCustom-grid-md-2-5 {
  flex-grow: 0;
  max-width: 14.2857142857%;
  flex-basis: 14.2857142857%;
  padding: 16px;
}

@media (max-width: 566px) {
  .MuiGridCustom-grid-md-2-5 {
    max-width: 100%;
    flex-basis: 100%;
  }
}

@media (min-width: 567px) and (max-width: 1920px) {
  .MuiGridCustom-grid-md-2-5 {
    flex-grow: 0;
    max-width: 33.3333%;
    flex-basis: 33.3333%;
    padding: 16px;
  }

  .MuiGridCustom-grid-md-2-5-3 {
    flex-grow: 0;
    max-width: 33.3333%;
    flex-basis: 33.3333%;
    padding: 16px;
  }
}

.activeButton {
  color: #343aa3;
  border-bottom: 1px solid #343aa3;
  padding: 0px 6px;
}

.notActiveButton {
  width: auto;
  padding: 0px 6px;
}

.notActiveButton:hover {
  cursor: pointer;
  border-bottom: 1px solid #343aa3;
}

.numberCircle {
  border-radius: 50%;
  width: 31px;
  height: 31px;
  padding: 3px 8px;
  background: #fff;
  border: 2px solid black;
  color: black;
  text-align: center;
  font: 20px Arial, sans-serif;
  font-weight: bold;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  width: auto;
  flex-wrap: wrap;
  padding: 0;
}

.pagination>li {
  margin: 5px 5px;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  padding: 0px !important;
  display: flex;
  user-select: none;
  cursor: pointer;
}

.pagination>li>a {
  text-decoration: none;
  padding: 4px 12px;
  display: block;
}

.pagination>li.active>a {
  color: white;
}

.pagination>li.active {
  background-color: #1f3a93;
}

.MuiGridCustom-grid-md-2-5-3 {
  flex-grow: 0;
  max-width: 28.5714285714%;
  flex-basis: 28.5714285714%;
  padding: 16px;
}

.MuiGridCustom-grid-md-2-5 {
  flex-grow: 0;
  max-width: 20%;
  flex-basis: 20%;
  padding: 16px;
}

@media (max-width: 566px) {
  .MuiGridCustom-grid-md-2-5 {
    max-width: 100%;
    flex-basis: 100%;
  }
}

@media (min-width: 567px) and (max-width: 1024px) {
  .MuiGridCustom-grid-md-2-5 {
    flex-grow: 0;
    max-width: 33.3333%;
    flex-basis: 33.3333%;
    padding: 16px;
  }

  .MuiGridCustom-grid-md-2-5-3 {
    flex-grow: 0;
    max-width: 33.3333%;
    flex-basis: 33.3333%;
    padding: 16px;
  }
}

.MuiGridCustom-grid2-md-2-5-3 {
  flex-grow: 0;
  max-width: 44%;
  flex-basis: 44%;
  padding: 5px;
}

.MuiGridCustom-grid2-md-2-5 {
  flex-grow: 0;
  max-width: 28%;
  flex-basis: 28%;
  padding: 5px;
}

@media (max-width: 566px) {
  .MuiGridCustom-grid2-md-2-5 {
    max-width: 100%;
    flex-basis: 100%;
  }

  .MuiGridCustom-grid2-md-2-5-3 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
  }
}

@media (min-width: 567px) and (max-width: 1024px) {
  .MuiGridCustom-grid2-md-2-5 {
    flex-grow: 0;
    max-width: 50%;
    flex-basis: 50%;
    padding: 5px;
  }

  .MuiGridCustom-grid2-md-2-5-3 {
    flex-grow: 0;
    max-width: 50%;
    flex-basis: 50%;
    padding: 5px;
  }
}

.color-critical {
  color: #e2211e !important;
}

.color-moderate {
  color: #ffd800 !important;
}

.color-moderate2 {
  color: #ffa412 !important;
}

.color-safe {
  color: #21ca5a !important;
}

.circle-critical {
  border: 2px solid #e2211e !important;
  color: #e2211e !important;
}

.circle-moderate {
  border: 2px solid #ffd800 !important;
  color: #ffd800 !important;
}

.circle-moderate2 {
  border: 2px solid #ffa412 !important;
  color: #ffa412 !important;
}

.circle-safe {
  border: 2px solid #21ca5a !important;
  color: #21ca5a !important;
}

.border-critical {
  border: 3px solid #e2211e !important;
}

.border-safe {
  border: 3px solid #21ca5a !important;
}

/* .textLeft {
  text-align: left !important;
} */

iframe {
  border: none;
  outline: none;
}

.csv-label {
  display: none !important;
}

.react-csv-input {
  margin-top: 0px !important;
  margin-right: 0px !important;
  display: none;
}

.csv-input {
  margin: 0px !important;
  padding: 0px !important;
  border: none !important;
}

.selectedLink {
  background-color: #ffa412 !important;
}

tr:focus {
  border: 2px dashed black;
}

.Toastify__toast--success {
  background-color: #218b34 !important;
}

.Toastify__toast--error {
  background-color: #e2211e !important;
}

.Toastify__toast--warning {
  background-color: #da9627 !important;
}

.Toastify__toast-body {
  font-size: 15px !important;
  font-weight: bold !important;
}

div[class^="PrivateHiddenCss-"] {
  height: 100% !important;
}

.MuiListItemText-primary {
  font-weight: 600 !important;
}

/* Date Range Picker Css Start */

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
  background: #1f3a93 !important;
  border: 1px double #1f3a93 !important;
  color: #fff !important;
}

.CalendarDay__selected_span {
  background: #7d95e4 !important;
  border: 1px double #7d95e4 !important;
  color: #fff !important;
}

.DateRangePickerInput__withBorder {
  border: 1px double #1f3a93 !important;
}

.DateInput {
  width: 105px !important;
}

.DateInput_input {
  font-size: 17px !important;
  padding: 5px 8px 3px !important;
}

.CalendarDay__hovered_span:hover, .CalendarDay__hovered_span {
  background: #7d95e4 !important;
  border: 1px double #7d95e4 !important;
  color: #fff !important;
}

/* Date Range Picker Css End */

/*  SECTIONS  */

.section {
  clear: both;
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-wrap: wrap;
}

/*  COLUMN SETUP  */

.col {
  display: block;
  padding: 1% 0 1% 0.5%;
}

.col:first-child {
  margin-left: 0;
}

/*  GROUPING  */

.group:before, .group:after {
  content: "";
  display: table;
}

.group:after {
  clear: both;
}

.group {
  zoom: 1;
  /* For IE 6/7 */
}

/*  GRID OF FIVE  */

.span_md_1_of_5 {
  width: 19.6%;
  /* min-height: 120px; */
}

.span_md_3_of_5 {
  width: 33%;
  /* min-height: 120px; */
}

.span_1_of_2 {
  width: 49.2%;
}

/*  GO FULL WIDTH BELOW 480 PIXELS */

@media only screen and (max-width: 786px) {
  .col {
    padding: 1% 0 1% 0.5%;
  }

  .span_md_1_of_5 {
    width: 32%;
  }

  .span_md_3_of_5 {
    width: 50%;
  }
}

@media only screen and (max-width: 540px) {
  .col {
    padding: 1% 0 1% 0.5%;
  }

  .span_md_1_of_5 {
    width: 50%;
  }

  .span_md_3_of_5 {
    width: 50%;
  }
}

@media only screen and (max-width: 480px) {
  .col {
    padding: 1% 0 1% 0.5%;
  }

  .span_md_1_of_5 {
    width: 100%;
  }

  .span_md_3_of_5 {
    width: 100%;
  }
}

/* Ribbon */

.ribbon {
  line-height: 1.7;
  font-size: 13px;
  text-transform: uppercase;
  text-align: center;
  display: block;
  width: 50px;
  height: 22px;
  right: -4px;
  top: 1px;
  color: white;
  position: absolute;
  border-bottom-left-radius: 10px;
  font-weight: 600;
  font-family: "Segoe UI";
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .ribbon {
    width: 42px;
  }
}

.ribbon-danger {
  background: #e2211e;
}

.ribbon-warning {
  background: #ffa412;
}

.ribbon-success {
  background: #21ca5a;
}

.ribbon-blue {
  background: #1f3a93;
}

/* card border */

.cardBorder-danger {
  border-bottom: 2px #e2211e solid !important;
}

.cardBorder-warning {
  border-bottom: 2px #ffa412 solid !important;
}

.cardBorder-success {
  border-bottom: 2px #21ca5a solid !important;
}

.recharts-wrapper {
  margin: auto !important;
}

.rotate-down {
  transform: rotate(90deg);
}

.rotate-up {
  transform: rotate(270deg);
}

.whiteBoxCardHeight {
  min-height: 140px;
}

@media only screen and (max-width: 1440px) and (min-width: 768px) {
  .whiteBoxCardHeight {
    min-height: 154px;
  }
}

.blueBoxCardHeight {
  min-height: 108px;
}

.MuiButtonBase-root {
  text-transform: capitalize;
}

/* .MuiOutlinedInput-input {
  padding: 12px !important;
} */

.MuiButtonBase-root:disabled {
  cursor: not-allowed !important;
  pointer-events: auto !important;
}

/* .archive-links-table
  > div
  > div
  > div
  > div
  > div
  > table
  > thead
  > tr:nth-child(1)
  .MuiSvgIcon-root {
  color: white !important;
} */

.ps-stic {
  position: sticky;
  display: flow-root;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  margin-top: 85px;
}

.ps-stic2 {
  display: flow-root;
  text-align: center;
}

.homepage-text p {
  font-size: 35px;
  color: #203b94;
  font-family: "Segoe UI", sans-serif;
}

.homepage-text span {
  font-weight: 700;
}

.dialog-text-free-users p {
  margin: 0;
  font-size: 20px;
  color: #203b94;
  font-family: "Segoe UI", sans-serif;
}

.dialog-text-free-users span {
  font-weight: 700;
}

.img-fluid {
  height: auto;
  width: 100%;
}

.MuiDrawer-paper {
  position: fixed !important;
}

.buttonCheck {
  font-size: 20px !important;
  line-height: 1.4 !important;
  min-height: 44px !important;
  border-radius: 30px !important;
  max-width: 135px !important;
  width: 100% !important;
  padding: 12px 16px !important;
  font-weight: bold !important;
  background-color: #E59EFE !important;
  color: #951888 !important;
}

@media only screen and (max-width: 600px) {
  .homepage-text p {
    font-size: 25px;
  }

  .buttonCheck {
    width: 120px !important;
    font-size: 12px !important;
  }
}

.sidebarIcons {
  color: white;
}

.MuiAccordionSummary-expandIcon {
  margin-right: -8px !important;
}

.icon-priority {
  font-weight: 500;
}

.activity-logs-table thead tr {
  border: 2px solid #1f3a93 !important;
}

@media only screen and (min-width: 1367px) {
  .box-container {
    padding: 15px 50px !important;
  }
}

.MuiButton-label {
  font-family: 'Segoe UI', 'sans-serif' !important;
  text-transform: capitalize !important;
}

.MuiIconButton-label {
  font-family: 'Segoe UI', 'sans-serif' !important;
}

.MuiTab-wrapper {
  font-family: "Segoe UI" !important;
}

/* .MuiListItemText-primary {
  font-family: "Segoe UI" !important;
} */

.MuiFormControlLabel-label {
  font-family: "Segoe UI" !important;
}

.Toastify__toast-container {
  z-index: 99999999 !important;
}

/* 
.MuiTableCell-root.MuiTableCell-body {
  word-break: break-all
} */

.MuiInput-underline:before {
  border-bottom: 2px #1F3A93 solid !important;
}

#scans-table div>div>div {
  min-height: 32px !important;
}

#scans-table>div>div:nth-child(1)>div:nth-child(1) {
  padding-top: 15px !important;
}

@media (min-width: 600px) {
  #scans-table div>div>div {
    min-height: 32px !important;
  }
}

.package-sub-heading {
  margin: 0;
  font-weight: bold;
}

.package-sub-detail {
  margin: 0;
}

@keyframes fadeout {
  0% {
    opacity: 0;
    transform: scale(0.6);
  }

  50% {
    opacity: 0.5;
    transform: scale(0.8);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate {
  animation: 0.3s ease-out fadeout 1;
}

.check-list {
  margin: 0;
  padding-left: 1.2rem;
}

.check-list li {
  position: relative;
  list-style-type: none;
  padding-left: 2rem;
  margin-bottom: 0.5rem;
  font-size: 16px;
  font-family: 'Segoe UI';
  font-weight: 500;
}

.check-list li:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: -2px;
  width: 7px;
  height: 13px;
  border-width: 0 3px 3px 0;
  border-style: solid;
  border-color: #00a8a8;
  transform-origin: bottom left;
  transform: rotate(45deg);
}

.MuiDialog-paperWidthLg {
  max-width: 1100px !important
}

.emailVerifyBar {
  width: 100%;
  position: absolute;
  left: 250px;
  top: 60px;
}

@media (max-width: 600px) {
  .emailVerifyBar {
    left: 0px;
  }
}

.manage-resolutions {
  max-height: 230px;
  overflow-y: auto;
}

.manage-resolutions h3 {
  margin-top: 0;
  margin-bottom: 10px;
  max-height: 230px;
}

.resolutions-table .MuiFormControlLabel-root {
  font-size: 14px;
  font-weight: 500;
  margin: 0px;
}

.resolutions-table .MuiFormControlLabel-label {
  margin-left: 10px;
}

.resolutions-table .MuiIconButton-root {
  padding: 0px;
}

.resolutions-table {
  margin-bottom: 20px;
  border-collapse: collapse
}

.resolutions-table td:first-child {
  min-width: 250px
}

.resolutions-table td {
  padding: 10px 15px;
  border: 1px solid grey;
  min-width: 170px
}

.resolutions-table td {
  padding: 10px 15px;
  border: 1px solid grey;
}

.stat-card-dash .MuiAccordionSummary-expandIcon.Mui-expanded {
  transform: rotate(0deg) !important;
}

.progress-box {
  padding: 10px !important;
}

.progress-box .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  margin: 0px;
}

@media (max-width: 600px) {
  .responsive-header {
    width: 100%;
  }
}

.MuiGrid-grid-md-2-5 {
  flex-grow: 0;
  max-width: 20%;
  flex-basis: 20%;
}

.read-more-button {
  cursor: pointer;
  text-decoration: underline;
  font-size: 11px;
  margin-top: 5px;
}

/* .drawer-links .MuiListItemText-root {
  margin: 0px !important
} */

.drawer-links .MuiListItem-root {
  margin: 5px 0px !important;
}

.mr-2 {
  margin-right: 0.6rem !important;
}

.ml-2 {
  margin-left: 0.6rem !important;
}

#react-select-3-listbox {
  min-width: 400px !important;
}

.MuiGrid-spacing-xs-2>.MuiGrid-item {
  width: 100%;
}

.DayPicker_focusRegion {
  margin-top: 70px !important;
}


.title-issue:hover {
  cursor: pointer;
  color: #2457a3;
  text-decoration: underline;
}

.MuiOutlinedInput-input {
  padding: 10.5px 14px !important;
}