.report-perf-boxes {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr;
  grid-gap: 25px;
}

.report-performance h3 {
  color: #3c9adc;
  font-weight: 500;
  font-size: 28px;
  margin-bottom: 4px;
}

.report-perf-box {
  display: flex;
  flex: 1 1 50%;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
}

.report-perf-box-text {
  padding: 18px 5px 18px 25px;
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
}

.report-perf-box-text h4 {
  font: 22px "Segoe UI",  sans-serif;
  letter-spacing: -0.0625em;
}

.report-perf-box-text i {
  margin-bottom: -2px;
}

.report-perf-box-text p {
  font-size: 15px;
  display: none;
}

.report-perf-box-text:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
}

.report-perf-box-performance h4 {
  margin: 12px;
}

.report-perf-box-performance .report-perf-box-result-numeric {
  padding-bottom: 5px;
}

.report-perf-box-expand {
  display: block;
}

.report-perf-box-expand h4 {
  margin: 0 0 16px;
}

.report-perf-box-expand h4 i {
  display: none;
}

.report-perf-box-expand p {
  margin: 0;
  display: inline;
}

.report-perf-box-result {
  width: 215px;
  display: flex;
  flex-direction: column;
}

.report-perf-box-result-message {
  font: 14px "Segoe UI",  sans-serif;
  letter-spacing: -0.0225em;
  width: 100%;
  text-align: center;
  padding: 7px 0;
}

.report-perf-box-result-numeric {
  font: 40px "Segoe UI",  sans-serif;
  letter-spacing: -0.0625em;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.report-perf-boxes-smaller {
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr 1fr;
}

.report-perf-boxes-smaller h4 {
  font-size: 18px;
  margin: 0;
}

.report-perf-boxes-smaller .report-perf-box-result-numeric {
  font-size: 22px;
}

.report-perf-boxes-smaller .report-perf-box-result {
  width: 90px;
}

.report-perf-box-rating-low .report-perf-box-result-numeric {
  background: #f5fcf4;
  color: #36a927;
}

.report-perf-box-rating-low .report-perf-box-result-message {
  background: #36a927;
  color: #fff;
}

.report-perf-box-rating-medium-low .report-perf-box-result-numeric {
  background: #f0f6e3;
  color: #99c144;
}

.report-perf-box-rating-medium-low .report-perf-box-result-message {
  background: #99c144;
  color: #fff;
}

.report-perf-box-rating-medium .report-perf-box-result-numeric {
  background: #fff2dd;
  color: #f6ab34;
}

.report-perf-box-rating-medium .report-perf-box-result-message {
  background: #f6ab34;
  color: #fff;
}

.report-perf-box-rating-medium-high .report-perf-box-result-numeric {
  background: #fff6e9;
  color: #c48a8a;
}

.report-perf-box-rating-medium-high .report-perf-box-result-message {
  background: #c48a8a;
  color: #fff;
}

.report-perf-box-rating-high .report-perf-box-result-numeric {
  background: #ffebe9;
  color: #ec685d;
}

.report-perf-box-rating-high .report-perf-box-result-message {
  background: #ec685d;
  color: #fff;
}

.report-perf-box-first-byte:before {
  background: #8a98c1;
}

.report-perf-box-first-contentful-paint:before {
  background: #2ebbf6;
}

.report-perf-box-largest-contentful-paint:before {
  background: #417be7;
}

.report-perf-box-interactive:before {
  background: #b06dc9;
}

.report-perf-box-speed-index:before {
  background: #f466a6;
}

.report-perf-box-onload:before {
  background: #a85c84;
}

.report-perf-box-fully-loaded:before {
  background: #b22f69;
}

.report-perf-box-cumulative-layout-shift:before {
  background: #4cc29d;
}

.report-perf-box-total-blocking-time:before {
  background: #6c467a;
}

.report-perf-box-redirect:before {
  background: #f4f4f4;
}

.report-perf-box-connect:before {
  background: #d9d9d9;
}

.report-perf-box-backend:before {
  background: #bebab9;
}

.report-perf-box-first-byte:before {
  background: #8a98c1;
}

.report-perf-box-first-paint:before {
  background: #506791;
}

.report-perf-box-dom-interactive:before {
  background: #527070;
}

.report-perf-box-dom-content-loaded:before {
  background: #408483;
}

@media not print {
  @media(max-width: 950px) {
    .report-perf-boxes {
      grid-template-columns: 1fr;
      grid-auto-rows: auto;
      grid-gap: 10px;
    }
    .report-perf-boxes-smaller {
      grid-template-columns: 1fr 1fr;
    }
    .report-perf-box-result-numeric {
      font: 30px "Segoe UI",  sans-serif;
      letter-spacing: -0.0625em;
    }
  }
  @media(max-width:800px) {
    .report-perf-boxes-smaller {
      grid-template-columns: 1fr;
    }
  }
}

@media(max-width:550px) {
  .report-perf-intro {
    flex-direction: column;
    align-items: center;
  }
  .report-perf-expand-wrapper {
    align-self: auto;
    margin-top: 15px;
  }
  .report-perf-box {
    flex-direction: column;
    margin: 30px 18px;
  }
  .report-perf-box .report-perf-box-result {
    width: 100%
  }
  .report-perf-box-text {
    padding: 15px;
    text-align: center;
    order: 2;
    justify-content: center;
  }
  .report-perf-box-text:before {
    right: 0;
    height: 4px;
    width: initial;
  }
  .report-perf-box-result-numeric {
    padding: 10px 10px 18px;
  }
}

.recharts-tooltip-wrapper {
  z-index: 10000 !important;
}